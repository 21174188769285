import { queryOptions, useQuery } from '@tanstack/react-query'
import {
  fetchCMSCatNavData,
  fetchCMSData,
  fetchDeferredHydration,
  fetchProgressiveRevealShelfData,
} from '@/services/CMS/fetchers'
import {
  useStoreParams,
  type StoreParams,
} from '@/utils/dataFetching/storeParams'
import { type getAddressParamsForCMS } from '@/services/CMS/utils'
import { useCMSParamsWithoutSlug } from '@/services/CMS/hooks'
import { useIsDataRightsRelatedQueryEnabled } from '@/services/DataPrivacy/hooks'
import { type Content } from '@/components/CMS/types'
import { useUserId } from '@/services/User/hooks'

type StoreParamsAndAddressParams = {
  storeParams?: StoreParams
  addressParams?: ReturnType<typeof getAddressParamsForCMS>
}
export const cmsQueryOptions = ({
  slug,
  storeParams,
  addressParams,
}: StoreParamsAndAddressParams & {
  slug: string
}) => {
  return queryOptions({
    queryKey: [`CMS_${slug}`, { slug, storeParams, addressParams }],
    queryFn: fetchCMSData,
  })
}

export const useQueryCMS = ({
  params,
  enabled,
  keepPreviousData,
}: {
  params: ReturnType<typeof useCMSParams>
  enabled: boolean
  keepPreviousData?: boolean
}) => {
  return useQuery({
    ...cmsQueryOptions(params),
    enabled: useIsDataRightsRelatedQueryEnabled(enabled),
    placeholderData: (prevData) => (keepPreviousData ? prevData : undefined),
  })
}

export const catNavCmsQueryOptions = ({
  storeParams,
  addressParams,
}: StoreParamsAndAddressParams) =>
  queryOptions({
    queryKey: ['CMS_CAT_NAV', { storeParams, addressParams }],
    queryFn: fetchCMSCatNavData,
  })

export const useQueryCatNavCMS = () => {
  const { storeParams, addressParams } = useCMSParamsWithoutSlug()
  return useQuery({
    ...catNavCmsQueryOptions({ storeParams, addressParams }),
    enabled: Boolean(storeParams.store_location_id),
  })
}

export const useCMSParams = (slug: string) => {
  const cmsParamsWithoutSlug = useCMSParamsWithoutSlug()
  return {
    ...cmsParamsWithoutSlug,
    slug,
  }
}

export const useFetchDeferredHydration = (
  hydrationCallback: Content['hydration_callback']
) => {
  return useQuery({
    queryFn: () =>
      fetchDeferredHydration(
        hydrationCallback ?? {
          url: '',
          headers: {},
          body: {},
        }
      ),
    queryKey: ['DeferredCallback', hydrationCallback?.url ?? ''],
    enabled: !!hydrationCallback,
  })
}

type ProgressiveRevealProps = {
  productId: number
  isProgressiveRevealExperiment: boolean
  searchQuery: string
}

const ProgressiveRevealShelf = 'Progressive reveal shelves' as const
export type ProgressiveRevealShelfQueryKey = [
  typeof ProgressiveRevealShelf,
  {
    storeParams: StoreParams
    productId: number
    searchQuery: string
    userId: number | null
  }
]

type ProgressiveRevealShelfQueryKeyParams = {
  storeParams: StoreParams
  productId: number
  searchQuery: string
  userId: number | null
}

const getProgressiveRevealShelfQueryKey = ({
  storeParams,
  productId,
  searchQuery,
  userId,
}: ProgressiveRevealShelfQueryKeyParams): ProgressiveRevealShelfQueryKey => {
  return [
    ProgressiveRevealShelf,
    { storeParams, productId, searchQuery, userId },
  ] as const
}

export type ProgressiveRevealShelfQueryKeyReturnType = ReturnType<
  typeof getProgressiveRevealShelfQueryKey
>

export const useQueryProgressiveRevealShelf = ({
  productId,
  isProgressiveRevealExperiment,
  searchQuery,
}: ProgressiveRevealProps) => {
  const storeParams = useStoreParams()
  const userId = useUserId()
  return useQuery({
    queryKey: getProgressiveRevealShelfQueryKey({
      storeParams,
      productId,
      searchQuery,
      userId,
    }),
    queryFn: async (context) => {
      const response = await fetchProgressiveRevealShelfData(context)
      if (response.data.items.length === 0) {
        return {}
      } else {
        return response
      }
    },
    enabled: isProgressiveRevealExperiment,
  })
}
