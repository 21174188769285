import { apiPost } from '@/utils/dataFetching'
import {
  type Content,
  type CMSCatNavData,
  type CMSPageData,
} from '@/components/CMS/types'
import { type QueryFunctionContext } from '@tanstack/react-query'
import { isOnServer } from '@shared/constants/util'
import { type StoreParams } from '@/utils/dataFetching/storeParams'
import {
  type getAddressParamsForCMS,
  createFetchCMSDataConfig,
  toCategory,
} from '@/services/CMS/utils'
import { type ShoppingCategories } from '@/services/ShoppingCategories/types'
import { getCMSWrapperProps } from '@/components/CMS/utils/getCMSWrapperProps'

export type CMSQueryKey = Readonly<
  [
    `CMS_${string}`,
    {
      slug?: string
      storeParams: StoreParams | undefined
      addressParams: ReturnType<typeof getAddressParamsForCMS> | undefined
    }
  ]
>

export type CMSDataQueryFunctionContext = QueryFunctionContext<CMSQueryKey>

export const fetchCMSData = (context: CMSDataQueryFunctionContext) => {
  return apiPost<CMSPageData>({
    config: {
      ...createFetchCMSDataConfig({
        context,
        isOnServer: isOnServer(),
      }),
      signal: context.signal,
    },
    context,
    fetcherName: 'fetchCMSData',
  })
}

export const fetchDeferredHydration = ({
  url,
  headers,
  body,
}: {
  url: string
  headers: Record<string, string>
  body: unknown
}) => {
  // url from hydration callback object does not include gateway path
  const fullUrl = `/cms${url}`
  return apiPost<Content>({
    fetcherName: 'fetchDeferredHydration',
    config: {
      url: fullUrl,
      headers,
      data: body,
    },
  })
}

export const fetchCMSCatNavData = async (
  context: CMSDataQueryFunctionContext
): Promise<ShoppingCategories> => {
  const response = await apiPost<CMSCatNavData>({
    config: createFetchCMSDataConfig({
      context,
      isOnServer: isOnServer(),
      isCategoryNav: true,
    }),
    context,
    fetcherName: 'fetchCMSCatNavData',
  })
  const { id: spyglassID } = getCMSWrapperProps({
    content_type_id: response.content_type_id,
    id: response.id,
  })
  return {
    category: response.data.category_navs?.map(toCategory) ?? [],
    featured_categories: response.data.featured_navs?.map(toCategory) ?? [],
    spyglassID,
  }
}
